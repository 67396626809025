
.main {
    position: relative;
    background-color: #ccc;
    padding: 10px;
    border-radius: 5px;
}

.fromLocale, .toLocale, .level {
    color: #666;
}

.text, .translation {
    padding: 10px 20px;
    font-size: 1.2em;
}

.text {
    font-weight:  bold;
}

.translations {
    display: flex;
    flex-direction: column;
}

.level {
    position: absolute;
    right: 10px;
    top: 10px;
}