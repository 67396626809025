.duplicate {
    background-color: #ffe693;
}

.blockedDuplicate {
    background-color: #ff2600;
}

.buttons {
    display: flex;
    gap: 10px;
    align-self: flex-end;
}