.main {

}

.solve {
    align-self: flex-end;
}

.knownBar {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.knownBar :first-child {
    width: 100%;
    height: 42px;
    line-height: 42px;
    text-align: center;
}
.knownBar :not(:first-child) {
    flex: 1 1 auto;
}

.viewCount {
    margin-left: auto;
}