.main {
    cursor: pointer;
    min-height: 42px;
    padding: 10px;
    font-size: 1em;
}

.main:disabled {
    cursor: default;
}
