.table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px 10px;
}

.add {
    align-self: flex-end;
}

.duplicate {
    background-color: #ffe693;

}

.blockedDuplicate {
    background-color: #ff2600;
}

.duplicate,
.blockedDuplicate {
    padding-right: 30px;
}

.inputContainer {
    position: relative;
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: 1;
}

.inputContainer>* {
    grid-row: 1;
    grid-column: 1;

}

.infoButton {
    justify-self: flex-end;
    height: 100% !important;
    font-size: 16px !important;
}

.inlineDuplicate {
    z-index: 10000;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 3px 2px 6px 1px #00000070;
    pointer-events: none;
    padding: 5px 10px;
    border: 1px solid #ccc;
    font-size: .8em;
    font-style: italic;
    color: #333;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.inputContainer:has(input:not(:focus-visible))>.inlineDuplicate {
    display: none;
}