.main {
    --card-height: 2em;
    --box-height: 1em;
    display:grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    padding-left: 1px;
    padding-right: 1px;
}

.box {
    position: relative;
    margin-left: -1px;
    margin-right: -1px;
}

.border {
    position:absolute;
    inset: 0;
    height: var(--box-height);
    top: calc( var(--card-height) - var(--box-height) + 3px);
    border:2px solid #000;
    border-top: none;
}

.cards {
    margin-left: 3px;
    width: calc(100% - 6px);
    height: var(--card-height);
    display:flex;
    gap: 1px;
    overflow-x: hidden;
    justify-content: flex-end;
}

.card {
    width: 1px;
    min-width: 1px;
    height: var(--card-height);
    background-color: #333;
}

.current .count {
    background-color: #ccc;
}

.count {
    text-align: center;
    padding: 0 3px;
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 1px;
}
