
.button {
    min-width: 0;
    min-height: 0;
    width: 42px;
    height: 42px;
    border: none;
    background: none;
    font-size: 40px;
    line-height: 25px;
    font-weight: bold;
    color: #000;
    padding: 0;
}

.button:disabled {
    color: #999;
}

.button:hover:not(:disabled) {
    color: #666;
}