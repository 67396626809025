
.title {
    font-weight: bold;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.buttons {
    display: flex;
    gap: 10px;
    align-self: flex-end;
}