.main {
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 42px;
}

.main:hover {
    background-color: #eee;
}

.cell {
    border: 1px solid #ccc;
    border-top: none;
    padding: 2px 10px; 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: 50%;
    overflow: hidden;
}

.cell:nth-child(2) {
    border-left: none;
}

.actions {
    position:absolute;
    top: 0;
    right: 1px;
    bottom: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: #eee;
}

/* .menu {
    top: 0;
    right: 45px;
    bottom: 0;
    background-color: #eee;
    box-shadow: 2px 2px 6px 0 #000;
} */