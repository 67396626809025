.main {
    display: flex;
    flex-direction: column;
}

.list {
    display: flex;
    flex-direction: column;
}

.caption {
    display: flex;
    flex-direction: row;
}

.caption * {
    border: 1px solid #ccc;
    font-weight: bold;
    padding: 2px 10px; 
    width: 50%;
    overflow: hidden;
}

.caption :last-child {
    border-left:none;
}
