.main {
    display: flex;
    flex-direction: column;
}

.header {
    background-color: #ccc;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    gap: 10px;
}

.home {
    width: 40px;
    height: 40px;
    background-image:url('../../public/logo192.png');
    background-size: cover;
    cursor: pointer;
    flex: 0 0 auto;
}

.title {
    flex: 1 1 100%;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.settings {
    width: 40px;
    height: 40px;
    flex: 0 0 auto;
}

.navbar {
    display: flex;
    padding: 20px 20px 0 20px;
    gap: 10px;
    align-items: center;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}