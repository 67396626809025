.background {
    position: absolute;
    inset: 0;
    background-color: #000;
    opacity: .1;
}

.container {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container > * {
    position: relative;
}

.dialog {
    border-radius: 5px;;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
