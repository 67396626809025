* {
    box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Tahoma;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#modal-root {
    position: absolute;
    inset: 0;
    pointer-events: none;
}

[data-has-modals='true'] > #root {
    user-select: none;
}