.main {
    position: absolute;
    top: 10px;
    right: calc(50% - 20px);
    width: 40px;
    height: 40px;
}

.main::before {
    content:'';
    position: absolute;
    width: 40px;
    height: 16px;
    top: 12px;
    left: 0;
    background-color: #ccc;
    border-radius: 50%;
    box-shadow: 1px 1px 10px 17px #ccc;
}

.first, .second, .third {
    position: absolute;
    border: 3px solid transparent;
    border-top-color: #2f169c;
    border-radius: 50%;
    animation: rotate linear infinite;
}

.first {
    height: 50%;
    width: 50%;
    left: 25%;
    top: 25%;
    animation-duration: 0.85s;
}

.second {
    height: 75%;
    width: 75%;
    top: 13%;
    left: 13%;
    animation-duration: 0.95s;
}

.third {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    animation-duration: 1.05s;
}

@keyframes rotate {
    from {
        transform: rotateZ(-720deg);
    }
    to {
        transform: rotateZ(0deg)
    }
}
