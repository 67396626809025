.main {
    position: relative;
}

.button {
    cursor: pointer;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
}

.menu {
    z-index: 1;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: rgb(255, 255, 255);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.397);
    padding: 10px;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
}