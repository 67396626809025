
.dialog {
    width: 600px;
    max-width: 100%;
    height: 400px;
    max-height: 100%;
}

.title {
    font-weight: bold;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
}

.textField {
    flex: 1 1 100%;
}

.buttons {
    display: flex;
    gap: 10px;
    align-self: flex-end;
}